import React, { Component } from "react";
// import simpleParallax from 'simple-parallax-js';
import Img from "gatsby-image";

class ParallaxImageMove extends Component {

  componentDidMount() {
    // const el = ReactDOM.findDOMNode(this);
    // const img = el.getElementsByTagName('img')[1];
    // new simpleParallax(img, {
    //   customContainer: "gatsby-image-wrapper",
    //   overflow:true,
    //   scale:1.3,
    //   orientation: 'up'
    // });
  }

  render () {
    const { imgData } = this.props;
    return(

      <Img
      fluid={imgData}
      fadeIn={false}
      alt=""
      style={{
        height: `100%`,
        width: `100%`
      }}
      />
    )
  }

}


export default ParallaxImageMove
