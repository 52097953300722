import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import TakeAction from "../components/take-action-component"
import ParallaxImageMove from "../components/parallax-image-move"

// svgs
import preservedBy from "../images/OklahomaStandard-PreservedBy-Navy.svg"
import blueBadge from "../images/OklahomaStandard-Badge-Blue.svg"
// svgs

const AboutPage = ({data}) => (
  <Layout>
    <SEO title="Oklahoma Standard - The Community Response to the April 19, 1995 Bombing" description="The Oklahoma Standard, preserved by the Oklahoma City National Memorial & Museum, lives on today as the model by which Oklahomans live their lives in response to the needs of their neighbors, fellow citizens, and communities." />
    <div className="columns has-background-link has-text-white is-vcentered is-marginless">
      <div className="column pa4" data-sal="slide-right" data-sal-delay="100" data-sal-duration="800">
        <h1 className="is-uppercase has-text-weight-black is-size-xl main-title" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.page_header_page[0]?.title }} />
      </div>
      <div className="column pa0">
        <div className="pa2 has-background-danger">
          <ParallaxImageMove imgData={data.wordpressPage.acf.page_header_page[0]?.hero_image.localFile.childImageSharp.fluid}/>
        </div> 
      </div>
    </div>

    <div className="columns column-pad mt5">
      <div className="column is-offset-2 is-4 tabcol" data-sal="fade" data-sal-duration="800">
        <p className="has-text-primary is-size-4 show-up">
          The Oklahoma Standard is a statewide initiative preserving and promoting a culture of caring citizens by encouraging Acts of
          Service, Honor, and Kindness.
        </p>
        
      </div>
      <div className="column is-4 is-offset-1 is-hidden-mobile tabhid" data-sal="fade" data-sal-duration="800">
        <img src={blueBadge} alt="Service. Honor. Kindness" width="50%" />
      </div>
    </div>

    <div className="columns column-pad mb5">
      <div className="column is-offset-2 is-8 " data-sal="fade" data-sal-duration="800" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
    </div>

    <div className="column-pad mv5-back mb5">
      <div className="columns">
        <div className="column is-5 is-offset-1 mt4">
          <p className="is-size-2 has-text-white preserved">
            The Oklahoma
            Standard is 
            preserved by the
            <span className="has-text-white has-text-weight-black"> Oklahoma City National Memorial &amp; Museum.</span>
          </p>
        </div>
        <div className="column is-5 has-text-centered	mt1 preserved-img is-hidden-mobile is-hidden-tablet-only">
          <a href="https://memorialmuseum.com/" target="_blank" rel="noopener noreferrer">
            <img src={preservedBy} width="175" alt="" />
          </a>
        </div>
      </div>

      <div className="columns abovefoot pt2 pb1">
        <div className="column is-4 is-offset-1" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.about_museum_content }} />
        <div className="column is-6">
          <ParallaxImageMove imgData={data.wordpressPage.acf.about_museum_image.localFile.childImageSharp.fluid}/>
        </div>
      </div>
    </div>

    <TakeAction />
  </Layout>
)

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
query {
  wordpressPage(slug: {eq: "about"}) {
    id
    content
    slug
    title
    acf {
      about_museum_content
      about_museum_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      page_header_page {
        __typename
        ... on WordPressAcf_page_header_content {
          title
          hero_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }

  banner: file(relativePath: { eq: "about/banner.jpg" }) {
    ...fluidImage
  }
  okc_memorial: file(relativePath: { eq: "about/18-ocn-006_okc_memorial_014@2x.jpg" }) {
    ...fluidImage
  }
  okc_memorial_2: file(relativePath: { eq: "about/18-ocn-006_okc_memorial_interiors_373@2x.jpg" }) {
    childImageSharp {
      fixed(width: 500) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  okc_memorial_3: file(relativePath: { eq: "about/museum_photography_3_175-180_combo_r@2x.jpg" }) {
    ...fluidImage
  }
  okc_memorial_overlook: file(relativePath: { eq: "about/overlook_332dw@2x.jpg" }) {
    ...fluidImage
  }
  okc_memorial_overlook_1: file(relativePath: { eq: "about/overlook_401_r@2x.jpg" }) {
    ...fluidImage
  }
  ambulance: file(relativePath: { eq: "about/ambulance@2x.jpg" }) {
    ...fluidImage
  }
  badge_blue: file(relativePath: { eq: "about/badge-blue@2x.png" }) {
    childImageSharp {
      fixed(width: 300) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`

export default AboutPage
